.tl_news, .ce_list_preselect {
  .news-item {
    a, .details {
      text-decoration: none !important;
      font-weight: $font-weight-light;
      
      figure {
        overflow: hidden;
        img {
          transform: scale(1);
          transition: 300ms transform;
        }
      }

      &:hover {
        figure {
          img {
            transform: scale(1.2);
            transition: 300ms transform;
          }
        }
      }
    }
  }
}

.form-column-login {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 0rem !important;
    padding-left: 0rem !important;

    a {
        text-decoration: none !important;
    }
}
.flatpickr-calendar {
  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      display: flex;
      
      svg {
        fill: $white;
      }
    }
  }
}

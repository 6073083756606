.mod_huh_menu.block {
  overflow: unset;
  .invisible {
    color: #fff;
  }
}

#bahnland_navigation_top_mobile:not(.mm-menu) {
  display: none;
}

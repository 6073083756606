.reader-navigation {
  border-bottom: 1px solid #bbb;
  margin-bottom: 45px;

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    a {
      text-decoration: none;
      
      a.prev:before {
        content: "<";
        margin-right: 5px;
      }

      a.next:after {
        content: ">";
        margin-left: 5px;
      }
    }

    .nav-item.next {
      margin-left: auto;
    }

    .nav-link {
      display: block;
      padding: .5rem 1rem;
    }
  } 
}

body #wrapper .pagination-wrapper {
  display: block;

  .pagination {
    .page-item {

      &.active {
        font-weight: $font-weight-bold;
        display: initial;
      }

      &.previous, &.next {
        font-size: 1.5em;
      }

      a.page-link {
        border: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}


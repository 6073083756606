.accordion {
  margin-bottom: 4rem;
  width: 75%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .ce_accordionStart,
  .ce_accordionSingle {
    border: none;
    border-bottom: 1px solid $cyan;
    margin-bottom: 0rem !important;

    .accordion-button {
      box-shadow: none;
      flex-direction: row;
      padding: 0.4rem 1.25rem;
      font-style: normal; //$headings-font-style;
      font-weight: $font-weight-medium;
      line-height: $headings-line-height;
      color: $headings-color;

      &:not(.collapsed) {
        color: $headings-color;
        font-weight: $font-weight-bold;
        background-color: #ffffff;
        box-shadow: inset 0 -1px 0 rgb(0 161 223 / 100%);
      }

      &:after {
        font-family: "beg-icons";
        background-image: none !important;
        width: 2rem;
        height: 2rem;
        speak: none;
        font-size: 2rem;
        //margin-left: 0.5rem;
        font-style: normal;
        color: $cyan;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        content: "\e757";
        margin-left: auto;
      }
    }

    .accordion-body {
      background-color: $light;

    }
  }
}

.accordion {
  &#accordion_18548 {
    width: 100%;
  }
}

// Corporate Portal: Aktuelle Infrastruk­tur­projekte

.mod_article.no-margin {
  .accordion-body {
    .ce_download, .ce_text {
      margin-bottom: 0rem !important;
    }
  }
}

.privacy-center {
  background-color: $white;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}

.privacy-center-settings {
  .accordion {
    width: 100%;
  }
}

.privacy-center-protected-code {
  .poster {
    .allow-protected-code {
      position: absolute;
      text-decoration: none !important;
    }
  }
}

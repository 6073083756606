@include headline-styles(1) {
  margin-bottom: 2.5rem;
  hyphens: auto;

  span.headline-indented {
    margin-left: 2rem;
    display: block;
  }

  @include media-breakpoint-up(md) {
    span.headline-indented {
      margin-left: 4rem;
    }
  }
}

@include headline-styles(2) {
  margin-bottom: 2rem;
}

@include headline-styles(3) {
  margin-bottom: 1.5rem;
  line-height: 1.09;
}
@include headline-styles(4) {
  margin-bottom: 1.5rem;
}

@include headline-styles(5) {
  font-style: normal;
  margin-bottom: 1.5rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#header {
  padding: 1rem 0 1rem;

  .header-logo {
    // LOGO
    img {
      padding-bottom: 1.5rem;
      max-height: 150px;

      @include media-breakpoint-down(sm) {
        padding-bottom: 0;
        height: 60px;
      }
      @include media-breakpoint-down(md) {
        height: 80px;
      }
    }
  }

  .navbar {
    @include media-breakpoint-down(sm) {
      padding: 0 0.5rem;
    }
  }

  // NAV ITEMS
  .header-nav-tools,
  .navbar-collapse {
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    .nav-link.active,
    .nav-link.submenu.trail {
      text-decoration: underline;
      color: $secondary;

      &:hover {
        text-decoration: none !important;
      }

      a {
        &:hover {
          text-decoration: none !important;
        }
      }
    }

    a {
      font-weight: $font-weight-bold;
      color: $primary;

      span {
        padding: 0 0 0 1rem;
      }

      button {
        span {
          padding: 0;
        }
      }

      &:hover {
        color: $secondary !important;
        text-decoration: underline;
      }
    }
  }

  .navbar-secondary {
    // Top Navigation

    .mod_customnav {
      margin-right: 1rem;

      a {
        font-size: 1rem;
        font-weight: 400;
        padding: 0rem 0rem;

        span {
          padding: 0 0 0 0.3rem;
        }

        .nav-link {
          padding: 0rem 0rem;
        }

        &:after {
          content: "|";
          padding-left: 0rem;
          padding-right: 0rem;
        }
      }

      .nav-link.last {
        &:after {
          content: "";
        }
      }
    }
  }

  // SUBMENU
  .nav-item.submenu {
    & > a {
      @include beg-icon($beg-icon-arrow_carrot-down, "after");

      &:after {
        vertical-align: middle;
        font-size: 1.5rem;
        color: $primary;
        display: inline-block;
      }

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .header-top,
  .sticky-nav {
    .navbar {
      position: static;
      // Level 2/Sub menu
      .level_2,
      .level_3 {
        left: 0;
        right: 0;
        list-style: none;
        margin-top: 1rem;
        padding: 1rem 0;
        position: absolute;
        width: 100%;
        z-index: 3;
        justify-content: flex-end;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 100%;
          width: 100vw;
          background-color: $blue-light;
          z-index: -1;
        }

        &:not(.open) {
          display: none !important;
        }

        .nav-link.active {
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }

        a {
          font-weight: $font-weight-normal;

          &:hover {
            text-decoration: underline;
            color: $primary;
          }
        }

        .container {
          display: flex;
          justify-content: flex-end;
          padding: 1rem 0;
        }
      }
    }
  }

  .sticky-nav {
    background: $white;
    padding: 0.5rem 0;
    transform: translateY(-100%);
    transition: all .3s ease;

    @include media-breakpoint-down(sm) {
      padding: 1rem 0;
    }

    .link-home-mobile {
      display: none;
    }

    img {
      padding-bottom: 0;
      display: none;
    }

    &.show {
      transform: translateY(0);
      img {
        display: block;
      }

    }
  }
}

#header,
.navbar-mobile-bottom {
  // ACCESSIBILITY
  .navbar-tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .easy-lang-link {
      display: inherit;

      a {
        color: $primary;
        font-size: 1.8rem;
        @include beg-icon($beg-icon-easy-lang);
        text-decoration: none;
        background-color: transparent;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        span {
          @include visually-hidden();
        }

        &::before {
          line-height: 40px !important;
        }

        &:hover {
          font-size: 1.6rem;
        }
      }
    }

    .accessibility-bar {
      display: flex;

      .motion-toggle-wrapper,
      .contrast-toggle-wrapper {
        button {
          color: $primary;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          font-size: 1.8rem;
          padding: 0rem;
          background-color: transparent;
          margin-left: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            @include visually-hidden();
          }

          &::before {
            line-height: 40px !important;
          }

          &:hover {
            font-size: 1.6rem;
            color: $secondary;
          }

          &[aria-checked="true"] {
            //color: $primary;

            &:hover {
              //color: $primary;
            }
          }
        }
      }

      .motion-toggle-wrapper {
        button {
          @include beg-icon($beg-icon-animation-stop);

          &:hover {
            @include beg-icon($beg-icon-animation-stop);
            //color: $primary;
            color: $secondary;
          }

          &[aria-checked="true"] {
            @include beg-icon($beg-icon-animation-play);
            color: $secondary;
          }
        }
      }

      .contrast-toggle-wrapper {
        button {
          @include beg-icon($beg-icon-contrast);

          &:hover {
            @include beg-icon($beg-icon-contrast);
          }

          &[aria-checked="true"] {
            @include beg-icon($beg-icon-contrast-full);
          }
        }
      }
    }
  }

  button {
    background: transparent;
    border: none;
  }

  .mod_search {
    margin: 0 2rem 0 1rem;

    @include media-breakpoint-down(md) {
      margin: 0 0.5rem 0 0.5rem;
    }

    .input-group {
      align-items: center;

      input[type="search"] {
        border: none;
        border-bottom: 1px solid $cyan;
        border-top: 1px solid transparent;
        padding: 0;
        background-color: transparent;
        font-size: 1rem;

        &:hover,
        &:focus {
          border-top-color: $cyan;
        }

        &::placeholder {
          color: $primary;
          padding-left: 0.3rem;
        }
      }

      button[type="submit"] {
        padding: 0 0.5rem;
        font-size: 0;
        @include beg-icon($beg-icon-search);
        color: $icon-color;

        &:hover {
          color: $secondary !important;
          background-color: transparent !important;
        }


        &::before {
          font-size: 1.5rem;
        }
      }
    }
  }
}

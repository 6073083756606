#hero {
  > .inside {
    @extend .container;

    @include media-breakpoint-only(xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

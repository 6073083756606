.navbar-mobile-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $primary;
  left: 0;
  height: 60px;
  z-index: 1000;
  padding: 0.5rem;
  display: flex;
  justify-content: center !important;
  align-items: center;

  &.scroll-down.hidden {
    transform: translateY(100%);
    transition: transform .25s linear,-webkit-transform .25s linear;
  }

  &.scroll-up {
    transition: transform .25s linear,-webkit-transform .25s linear;
    transform: translateY(0);
  }

  .mod_search {
    position: relative;
    margin-bottom: 0;
    margin-right: auto;

    form {
      position: relative;
    }

    .formbody input[type=search] {
      display: none;
    }
  }
 
   .navbar-tools {
     .mod_search .input-group button[type=submit] {
       padding: 0rem !important;
     }
    
      .mod_search .input-group button[type=submit]::before {
      font-size: 1.3rem !important;
      padding-left: 0.3rem;

      &:hover {
        background-color: $white !important;
        color: $icon-color !important;
      }
     }

    .contrast-toggle-wrapper button.contrast-toggle, .motion-toggle-wrapper button.motion-toggle, .mod_search .input-group button[type=submit], .easy-lang-link a {
      //color: $white !important;
      //background-color: transparent !important;
      border-radius: 50%;
      border: none !important;
      &:hover {
        background-color: $primary;
        &:before {
          color: $white;
        }
        //background-color: transparent !important;
        border: 1px solid $white !important;
      }
    }

     .mod_search .input-group button[type=submit] {
       width: 40px;
       height: 40px;
       &:before {
         padding: 0;
       }

     }

    .easy-lang-link a {
      margin-left: 0.5rem;
    }

    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
      padding-top: 0.4rem !important;
    }
   }
}

.tiny-slider-container {
  .block {
    margin-bottom: 1rem !important;
  }
}

.tiny-slider {
  .tns-nav {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    button {
      @include clear-btn();
      width: 1rem;
      height: 1rem;
      background-color: $blue;
      border-radius: 50%;
      border: 1px solid white;
      margin: 0.3rem;

      &.tns-nav-active {
        background-color: $primary;
      }

    }
  }

  .tns-item {
    > .block, &.block {
      margin-bottom: 0 !important;
    }
  }

  .tns-outer {
    position: relative;

    .tns-controls {
      button[data-controls=prev], button[data-controls=next] {
        font-size: 4rem;
        border-radius: 50%;
        border: none;
        color: $primary;
        background: transparent;
        //color: $white;
        position: absolute;
        z-index: 1;
        top: 40%;

        @include media-breakpoint-down(sm) {
          color: $white;
          margin-top: -10%;
        }

        // icon-navi inside
        //@include xs {
        //  padding-left: 2rem;
        //  padding-right: 2rem;
        //  color: $white;
        //}
        //
        //@include md {
        //  padding-left: 3rem;
        //  padding-right: 3rem;
        //  color: $white;
        //}
        //
        //@include lg {
        //  padding-left: 5rem;
        //  padding-right: 5rem;
        //  color: $white;
        //}


        .title {
          //display: none;
          @include visually-hidden;
          @include visually-hidden-focusable;
        }

        &::before {
          display: flex;
          align-items: center;
          padding: 0.4rem 0;
          @include media-breakpoint-down(sm) {
            text-shadow: 0 0 5px #000000;
          }
        }
      }

      button[data-controls=prev] {
        @include beg-icon($beg-icon-arrow_carrot-left_alt2);
        left: -6%;

        @include media-breakpoint-down(sm) {
          left: 0;
        }
      }

      button[data-controls=next] {
        @include beg-icon($beg-icon-arrow_carrot-right_alt2);
        right: -6%;
        @include media-breakpoint-down(sm) {
          right: 0;
        }
      }

      button[disabled] {
        color: $gray-400;
        cursor: not-allowed;
      }
    }
  }

  .tns-slide-number {
    position: absolute;
    margin: 0 auto 0.5rem auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    width: fit-content;
    background: $white;
    border-radius: 50rem;
    padding: 5px;

    p {
      margin-bottom: 0;
      display: inline-block;
    }
  }
}

.leisure-destinations {
  .tiny-slider {
    button[data-controls=prev] {
      @include media-breakpoint-up(lg) {
        @include beg-icon($beg-icon-arrow_carrot-left_alt2);
        left: -60% !important;
      }
    }
  }
}

.publication-item {
  .product-header {
    height: 12%;
  }

  .cart-qty {
    height: 16%;
  }
}


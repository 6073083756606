.btn:not(.btn-link), button[type="submit"], input[type=submit] {
  /* Skew */
  transform: $btn-transform;
  font-family: $btn-font-family;
  //text-transform: uppercase;

  .input-group-append & {
    transform: none;
  }
}

.btn-primary, button[type="submit"], input[type=submit], .submit.button_update {
  background-color: $btn-primary-bg-color;
  color: $btn-primary-color;
  position: relative;
  border: 0;
  border-radius: 0;

  a {
    text-decoration: none;
    @extend .stretched-link;
  }

  &:hover {
    background-color: $primary !important;
    color: $white !important;

    a {
      color: $white !important;
    }
  }
}

.btn-white {
  background-color: $white;
  color: $primary !important;
  &:hover {
    background-color: $yellow;
  }
}

button[name=downloadSingleProduct] {
  @include beg-icon($beg-icon-download);
}

button[name=add_to_cart] {
  @include beg-icon($beg-icon-cart);
}

button[name=downloadSingleProduct], button[name=add_to_cart] {
  transform: none !important;
  background: transparent;
  color: white;
  font-size: 2.5rem;
  padding: 0;
  border-radius: 50%;
}

input[type=number] {
  width: 4rem;
  margin-right: 1rem;
  border-radius: 5px;
  background: transparent;
  border-color: $white;
  color: $black;
}
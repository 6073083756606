.mod_filecredits_list {
  .file-credit {
    word-break: break-all;
  }
}

.filecredits-list {

  .filecredits-list__item h5 {
    text-wrap: wrap;
    white-space: wrap;
    word-break: break-all;
  }

  .filecredits-list__item__image {
    display: block;

    img {
      display: block;
      margin-bottom: .25rem;
    }

    figcaption {
      font-size: .87rem;
      color: #041014;
    }
  }
}

form {
  .widget:not(.form-control) {
    margin-bottom: 1.5rem;
  }

  .formbody .form-group label, .form-label {
    color: $blue-dark;
    font-weight: $font-weight-bold;
    margin-bottom: 0.5rem;
  }

  .form-control,
  .form-select {
    border: 1px solid $secondary;
  }

  p.error {
    color: $white;
    background-color: #b3003e;
  }

  select {
    color: $black !important;
    appearance: auto;
    cursor: pointer;
    padding: 0.8rem 0;
  }

  input,
  select,
  textarea {
    &.form-control,
    &.form-select {
      border-radius: $input-border-radius;
    }
  }

  select.form-control:not(.form-select) {
    @extend .form-select;
  }

  .input-group {
    .btn:not(.btn-search) {
      border: $input-border-width solid $input-group-addon-border-color;
      transform: none;
      color: $input-group-addon-color;
      padding: $input-group-addon-padding-y $input-group-addon-padding-x;
    }

    :first-child {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
    :last-child {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
    :not(:first-child):not([type="search"]) {
      border-left: 0;
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    :not(:last-child):not([type="search"]):not(.choices__inner) {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.mod_md_subscribe {
  form {
    .formbody {
      @include make-row();

      .widget {
        @include make-col-ready();

        &:not(.email):not(.privacy-info):not(.widget-submit) {
          @include make-col(6);
        }
      }
    }
  }
}

// error messages

.alert-success,
.mod_status_messages .msg-success {
  color: $white;
  font-weight: $font-weight-bold;
  background-color: $primary;
  border: 0px;
  border-color: $white;
}

.alert-success {
  padding: 0.5rem;
  margin-bottom: 0rem;
}

.mod_status_messages {
  margin-bottom: 2rem !important;
}

.contactform .formbody .row {
  .form-check {
    margin-bottom: 0;
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .form-group.explanation {
    margin-top: 0;
  }
}

.ce_toplink {
  a {
    position: fixed;
    bottom: 4.5rem !important;
    right: 2.5rem;
    transform: scale(0, 0);
    transition: transform 250ms ease;
    transform-origin: center center;
    z-index: 999;
    //padding: 0.7rem 1.1rem;
  //font-size: 0;
    text-decoration: none;
    color: $primary;
    background-color: $white;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    @include beg-icon($beg-icon-arrow_carrot-up-double);

    @include media-breakpoint-down(xs) {
      bottom: 0.5rem;
    }

    &:before {
      background: $white;
      color: $primary;
      border-radius: 50%;
    }

    &.visible {
      transform: scale(1, 1);
    }

    &:before {
      font-size: 3rem;
    }
  }
}

body {
  overflow-x: hidden;

  > #wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // flex-shrink: 0!important;

    #main {
      padding-top: 3.5rem;

      [class^="ce_"]:not([class*="ce_headline"]):not([class*="ce_beg_headline"]):not([class*="ce_colset"]):not(img):not([class*="ce_tiny-slider-content-separator"]):not([class*="ce_tiny-slider-separator"]), 
      [class^="mod_"]:not([class*="mod_article"]):not(.huh-list), .accordion {
        @extend .mb-5-ni;
        @extend .mb-md-6-ni;
      }
    }
  }
  .ce_text {
    hyphens: auto;
    overflow-wrap: break-word;
  }
}

body {
  label.visually-hidden {
    color: $white;
    background-color: $primary;
  }

  &.high-contrast {

    // Verbindungsauskunft 

    .flatpickr-day.nextMonthDay,
    .flatpickr-day.prevMonthDay,
    .flatpickr-day.flatpickr-disabled {
      color: $black;
    }

    .flatpickr-current-month .numInputWrapper span.arrowUp:after,
    .flatpickr-current-month .numInputWrapper span.arrowDown:after {
      border-bottom-color: $white;
    }

    .flatpickr-current-month .numInputWrapper span.arrowDown:after {
      border-top-color: $white;
    }

    .flatpickr-day:hover {
      background: $primary;
      border-color: $primary;
      color: $white;
    }

    form button[type=submit].btn.btn-reset-muted {
      color: $primary;
    }

    .privacy-info {
      &.text-muted {
        color: $black !important;
      }
    }

    .accordion-button {
      &:not(.collapsed) {
        color: $white !important;
        background-color: $primary !important;
      }

      &:after {
        color: $white !important;
      }
    }

    .accordion-item {
      background-color: $white;
      //border: 1px solid $primary;
    }

    .accordion-body {
      background-color: $white !important;
      border: 1px solid $primary;
    }

    .accordion .ce_accordionStart {
      border-bottom: 1px solid $primary;
    }

    // Regionalkonferenzen

    .year-filter .mod_filter form {
      select {
        border: 1px solid $primary;
      }
    }

    .mod_filter form .year input[type=radio]:checked+label {
      background-color: #000 !important;
    }

    label {
      color: $primary !important;
      //background-color: $primary !important;
      font-weight: $font-weight-bold;
      // &:hover {
      //   background-color: #000 !important;
      // }
    }

    .mod_filter form .year label:hover {
      background-color: $primary !important;
      color: #fff !important;
    }

    .mod_filter form .year label {
      transition: background-color .3s, padding .3s, color .3s;
    }

    .bg-blue-light,
    .bg-light,
    .bg-blue,
    .bg-blue-medium,
    .bg-secondary {
      background-color: $white !important;
    }

    .bg-primary {

      input[type=number],
      label {
        color: $white !important;
      }

      .form-select,
      .form-control,
      .form-check-input {
        border: 1px solid $white !important;
      }
    }

    .mod_iso_productlist {
      .quantity_container label {
        color: $white !important;
      }
    }




    .ce_huh_hero {
      .btn-primary {
        background-color: $primary !important;
        color: $white !important;

        &:hover {
          background-color: $secondary !important;
          color: $white !important;
        }
      }

      .text-yellow {
        color: $white !important;
      }
    }

    .text-secondary,
    .text-yellow {
      color: $primary !important;
    }

    .ce_toplink {
      a {
        color: white;
        background-color: $primary;

        &:before {
          background: $primary;
          color: white;
        }
      }
    }

    .mm-navbar__title {
      color: $black;
    }

    .privacy-center {
      border-top: 2px solid $primary;

      .form-check-input:disabled {

        &,
        &~.form-check-label {
          opacity: 0.9;
        }
      }
    }

    #header {
      .header-top .navbar .level_2 {
        background-color: $white;
        border-top: 2px solid $primary;
        border-bottom: 2px solid $primary;
      }

      .sticky-nav {
        border-bottom: 2px solid $primary;
      }

      .navbar-tools a:before {
        color: $white !important;
      }

      .navbar-tools .easy-lang-link a,
      .contrast-toggle-wrapper button.contrast-toggle,
      .motion-toggle-wrapper button.motion-toggle {
        background-color: $primary !important;
        color: $white;
        border: 0;
      }

      // Navigation

      .nav-link.active,
      .nav-link.submenu.trail {
        color: $black;
        font-weight: $font-weight-bold;
      }
    }

    //mmenu Hintergrund Subnavi

    #mm-1.mm-panel {
      //border-top: 2px solid $icon-color;
      //border-bottom: 2px solid $icon-color;
    }

    #mm-1.mm-panel,
    .mm_listitem.active {
      background-color: $white;
    }

    #bahnland_navigation_top_mobile.mm-menu_popup .mm-panels .level_2 li,
    #bahnland_navigation_top_mobile.mm-menu_popup .mm-panels .level_2 .mm-listitem_selected {
      background-color: $white;
    }

    .mm-panel .level_2 {

      li.mm-listitem_selected {
        //background-color: $white !important;
      }
    }

    form {
      p.error {
        background-color: #b3003e;
      }
    }

    .tns-nav {
      button {
        background-color: $white;
        border: 2px solid;

        &.tns-nav-active {
          background-color: $primary;
          border-color: $white;
        }
      }
    }

    .leisure-destinations {
      .news-item-contentwrapper {
        background-color: rgba(0, 0, 0, .9) !important;
        border-left: 1px solid white;
      }
    }

    .btn-white {
      color: $blue-dark !important;
    }

    // Hero Superjobs 

    .ce_colsetStart {
      &.bg-gradient {
        background-image: none;
        background: $primary;
      }
    }

    .ce_huh_hero .hero-container.bb {
      background: none;
    }

    .bg-gradient {
      background-image: none;
      background: $primary !important;
    }

    .ce_huh_hero {
      .hero-container.bb {
        .diamond-gradient-hero-bg {
          background-image: url(../../img/raute/raute-blau.svg);
        }
      }
    }
  }

  .ce_survey.lieberbahn .form .formbody .surveyblock .widget div {
    background-color: $white;
    margin-bottom: 1rem;
    border-radius: 5px;
    color: $primary;
  }


  // .nav-link.active,
  // .nav-link.submenu.trail {
  //   color: $primary !important;
  // }


  // END CONTRAST

  &.using-keys {

    a:focus,
    a:active,
    button:focus,
    button:active,
    input:focus+label,
    input:focus,
    input:active,
    select:focus,
    div[role="button"]:focus,
    div[role="button"]:active {
      outline: 3px solid rgba(255, 255, 255, 1) !important;
      box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
      border-radius: 0 !important;
    }

    // special cases e.g TinySlider images
    a:focus {

      div,
      img {
        &:first-child {
          outline: 3px solid rgba(255, 255, 255, 1) !important;
          box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
          border: 3px solid $black;
        }
      }
    }

    // Versteckte Radiobutton Regionalkonferenzen/
    .mod_filter form .year label:focus {
      outline: 3px solid rgba(255, 255, 255, 1) !important;
      box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
      border: 3px solid $black;
    }

    // Multifileupload/Dropzone
    .attachments {
      .dz-container:focus {
        outline: 3px solid rgba(255, 255, 255, 1) !important;
        box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
        border-radius: 0 !important;
      }
    }

    // SVG Karte Tickets der Verbünde
    .network-map {
      a:focus {
        box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 1) !important;
        border-radius: 0 !important;
      }
    }

    .news-item a:focus {
      display: block;

      img {
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .quality-ranking-full {
      .qr-number {
        background-color: $primary;
      }
    }

    // Linkteaser Icon 
    .ce_linkteaser {
      &.bg-blue-light {
        border: 3px solid $primary;
      }

      .teaser {
        a {
          &.link-download {
            &:after {
              color: $primary;
            }
          }
        }
      }
    }

    .ce_linkteaser .teaser a.link-download:after {
      color: $primary !important;
    }

  }


  //.external-link {
  //  &:not(.syndication-link),
  //  &:not(.syndication-link)
  //  {
  //    @include beg-icon($beg-icon-external-link-thin);
  //
  //    &::before {
  //      display: inline-block;
  //      margin-right: 0.3rem;
  //      font-size: 1rem;
  //      font-weight: bold;
  //    }
  //  }
  //
  //  &.nav-link {
  //    span {
  //      padding: 0 0.5rem 0 0 !important;
  //    }
  //  }
  //}

  //.footer-app {
  //  .external-link {
  //    &::before {
  //      content: unset;
  //    }
  //  }
  //}

}
.col-full {
  @include media-breakpoint-up(sm) {
    @include col-full;
  }

  > .col-12 {
    padding: 0 !important;

    > .container {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
}

.footer-partner {
  .ce_colsetStart.row-partner {
    align-items: flex-end;
  }
}

// 5 Columns 
@media (min-width: 768px){
  .fivecolumns .col-12{
      width: 20%;
      *width: 20%;
  }
}
@media (min-width: 1200px) {
  .fivecolumns .col-12 {
      width: 20%;
      *width: 20%;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .fivecolumns .col-12 {
      width: 20%;
      *width: 20%;
  }
}

// Abstand raus u.a. beim Text-Medien-Element

.no-gap {
  --bs-gutter-x: 0rem;
}
.ce_huh_media_text {
  overflow: hidden !important;

  .row {
    --bs-gutter-x: 0;  
  }

  .box-content {
    padding: 5rem 4rem;
    
    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }
  }

  .box-media {
    .img-container {
      height: 100%;
      width: 100%;
    }
  }

  .image-size-half {
    @include media-breakpoint-up(lg) {
      min-height: 378px;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 440px;
    }
  }

  &.pic-small {
    .box-media {
      @include media-breakpoint-up(lg) {
        width: 33%;
      }
      figure img {
        object-fit: contain;
      }
    }
    .box-content {
      padding: 1rem 4rem;
    }
    @include media-breakpoint-up(lg) {
      .box-content.col-lg-6 {
        width: 66%;
      }
    }
  }
  .image-size-half {
    @include media-breakpoint-up(lg) {
      min-height: 240px;
    }
  }
}
  

.mod_search {
  mark {
    font-weight: bold;
  }

  input[name="keywords"] {
    border-color: $primary;
  }
  .visually-hidden {
    color: #fff;
  }
  &.search .search-actions {
    flex-direction: column;

    p.header {
      margin-top: 2rem !important;
    }
    
    button {
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
      margin-bottom: 3rem;
    }
  }
}

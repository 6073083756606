// Aktuelles Bildergalerie

.ce_gallery .cols_2 li figure {
  margin-bottom: 3rem !important;
  padding-bottom: 4rem !important;
}

.ce_gallery.gallery-two-per-row {

  .image_container {
    display: block !important;
    margin: 0;

    a {
      text-decoration: none;
    }

    .caption  {
      margin-top: 0.5rem;
    }
    }
}

.network-graph {
  .ce_text.block {
    padding: 0 !important;
  }

  .info-button-container {
    button{
      color: $white;
      margin-bottom: 1rem;
      background-color: $primary;

      &:hover {
        color: $white;
        border-color: $primary;
        background-color: $primary;
      }

      &:first-child {
        margin-right: 1rem;
      }

      &.active {
        color: $primary;
        border-color: $primary;
        background-color: $white;
      }

      &.highlight {
        color: $white;
        border-color: $primary;
        background-color: $primary;
      }
    }
  }

  [data-role="modal-trigger"]:hover  {
    cursor: pointer;
    color: $primary;
  }

  svg {
    .highlight[data-role="modal-trigger"] {
      stroke: $primary;
      stroke-width: 4;
    }
  }

  .map {
    overflow: auto;
  }
}

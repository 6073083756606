// Standalone links
.link-with-icon-blue, .box-link a:not(.btn) {
  text-decoration: none !important;
  font-style: italic;
  font-weight: $font-weight-medium;
  font-family: $font-family-poppins;
  @include beg-icon($beg-icon-arrow_carrot-right, after);

  &:after {
    display: inline-block;
    vertical-align: middle;
    color: $cyan;
    font-size: 1.5em;
  }

  &:hover {
    //color: $cyan;
    text-decoration: underline !important;
  }
}

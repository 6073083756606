#footer {
  margin-top: auto;

  .footer-partner {
    margin-bottom: 2rem;

    .logos {
      align-items: flex-end;
    }

    .col_1 {
      display: flex;
      align-items: center;
      
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }

    }
    .col_2.last {
      padding-top: 1rem;
      p {
        margin: 0;
      }
    }

    .ce_image {
      // Partner Logos
      width: 100%;
      .image_container {
        justify-content: flex-end;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          margin-bottom: 2rem;
        }
      }
    }
  }

  .footer-nav {
    color: $white;
    font-size: 1rem;

    .container {
      padding-top: 1rem;
      padding-bottom: 1rem;

      section {
        align-items: flex-start;
      }
    }

    .ce_image.siegel {
      .image_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;

          @include media-breakpoint-down(lg) {
            flex-direction: column;
            justify-content: center;
          }

          .caption {
            font-size: 1rem;
            margin-left: 1.5rem;
            padding-top: 18px;

            @include media-breakpoint-down(md) {
              padding-top: 10px;
            }
          }
    }
    }

    .nav {
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(sm) {
        justify-content: center !important;
      }

      .nav-link, a:link {
        color: $white !important;
        text-decoration: none;
        padding: 0 0 1rem 1rem;

        &.last {
          padding-right: 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    // All right reserved text
    .ce_text {
      p {
        margin: 0;
      }

      @include media-breakpoint-down(lg) {
        padding: 1rem;
        text-align: center;
      }
    }
  }
}

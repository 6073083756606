.avz-viewer {

  .avz-feature {
    //stroke: gray;
    //fill: #FFFFFF;
    fill: transparent;
    stroke-linecap: round;
    stroke-linejoin: round;

    &:not(.no-action) {
      cursor: pointer;

      &.avz-feature-mouseover,
      &.avz-feature-selected,
      &.avz-feature-search-highlight[tabindex="0"] {
        stroke:$red;
        fill:$red;
        stroke-opacity: 1;
        fill-opacity: 1;
      }
    }
  }

  .avz-button {
    margin-left: 0 !important;
    margin-top: 10px !important;
    margin-right: 4px;
    height: 40px;
    width: 40px;
    box-shadow: none;

    &.avz-toolbar-button {
      border: 0;
      background: $primary;
      text-align: center;

      &:hover {
        background: $primary;
        cursor: pointer;
        .avz-icon {
          &:before {
            color: $yellow;
          }
        }
      }
    }

    &.avz-button-home {
      margin-left: 10px !important;
    }

    .avz-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        font-size: 22px;
        color: #fff;
        line-height: 34px !important;
      }

      &.avz-home {
        @include beg-icon($beg-icon-house);
      }

      &.avz-zoom-in {
        @include beg-icon($beg-icon-plus);
        &:before {
          font-size: 28px;
        }
      }

      &.avz-zoom-out {
        @include beg-icon($beg-icon-minus);
        &:before {
          font-size: 28px;
        }
      }
    }
  }
}

.avz-callout-bubble {
  background: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 7px;
  -moz-box-shadow: 0px 1px 4px #777777;
  -webkit-box-shadow: 0px 1px 4px #777777;
  box-shadow: 0px 1px 4px #777777;
}

/*******************************************************************************
** Styling options for viewer widgets
*******************************************************************************/

/* avz-icon is applied to the embedded svg images used in buttons. */
.avz-icon {
  fill: #222222;
  fill-opacity: 1.0;
  width: 100%;
  height: 100%;
}

/* avz-widget is applied to all widgets in the viewer. */
.avz-widget {
  vertical-align: middle;

  /* this will prevent selecting viewer widgets for copy and paste */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
avz-widget-touch gets applied briefly to widgets after they are actived (clicked or tapped) to provide some visual
feedback.
*/
.avz-widget-activate {
  background: #E5E5E5;
}

/* avz-button is applied to all buttons. Buttons are DIV elements. */
.avz-button {
  margin: 0;
  background: $primary;
}

.avz-button:hover {
}

/*
avz-toolbar-button is applied to buttons that appear along the edge of the
viewer (e.g. the zoom in and zoom out buttons)
*/
.avz-toolbar-button {
  width: 32px;
  height: 32px;
}

.avz-toolbar-button:hover {
}

/*
avz-button-notouch is only applied on non-touch devices.  Hover behaviour is
inconsistent on touch devices so we only apply it if not
*/
.avz-toolbar-button-notouch:hover {
  background: #E5E5E5;
}

/*
avz-layer-list is applied to the layer list that appears after the layer list
button is pressed.  The layer list is an HTML UL element.
*/
.avz-layer-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.avz-layer-list li {
  display: block;
  white-space: nowrap;
  height: 32px;
  line-height: 32px;
}

.avz-layer-list li img {
  vertical-align: middle;
}

.avz-layer-list li:hover {
}

/*
avz-layer-list-notouch is only applied on non-touch devices.  Hover behaviour
is inconsistent on touch devices, so we only apply it if not.
*/
.avz-layer-list-notouch li:hover {
  background: #E5E5E5;
  cursor: default;
}

/*
avz-search-box is applied to the search box widget.  The search box is an HTML
DIV element containing an HTML INPUT text box and a clear button.
*/
.avz-search-box {
  padding: 2px;
}

.avz-search-box-input {
  font-size: small;
  border: none;
  outline: none;
}

.avz-search-box-clear-button {
  width: 1em;
  height: 1em;
  margin: .3em;
}

.avz-search-box-clear-button:hover {
  background: none;
  cursor: default;
}

@media screen and (-moz-images-in-menus: 0) {
  #formhybrid_mapublisher_node_filter_form {
    form {
      display: flex;
    }
    .bootstrap-tagsinput {
      .tag {
        [data-role="remove"] {
          margin-top: -21px;
        }
      }
    }
  }
}

.mod_mapublisher {
  //max-width: 1920px;
  //max-height: 2059px;
  margin-top: 35px;

  .widget-submit {
    display: none;
  }

  #map_container {
    overflow: hidden;

    #formhybrid_mapublisher_node_filter_form {
      form .formbody {
        display: flex;
        button[type="submit"] {
          font-size: 0;
          transform: none;
          @include beg-icon($beg-icon-search);
          &:before {
            font-size: 1.5rem;
          }
        }
      }
    }

    > form {
      > div:first-child {
        margin-top: 35px !important;
      }
    }

    @include media-breakpoint-up(sm) {
      &.fixed-controlls {
        .formbody {
          position: fixed;
          min-width: 25%;
          top: 60px;
        }

        > div:first-child {
          position: fixed !important;
          top: 50px !important;
          left: auto !important;
        }

      }
    }

    @include media-breakpoint-only(xs) {
      label.tagsinput {
        overflow: hidden;
      }
    }

    .autocomplete_results_container {
      .autoComplete_selected {
        background: $primary;
        color: $white;
      }

      .autoComplete_result {
        &:focus, &:hover {
          background: $primary;
          color: $white;
        }
      }

    }
  }

  #formhybrid_mapublisher_node_filter_form {
    position: absolute;
    left: 140px;
    margin-top: 10px;
    z-index: 9;
    width: 30%;
    padding-top: 0 !important;

    @include media-breakpoint-only(sm) {
      width: 35%;
    }

    @include media-breakpoint-only(xs) {
      width: 50%;
    }

    label,
    .submit {
      display: none;
    }

    .form-group {
      margin-bottom: 0;
    }

    .bootstrap-tagsinput {
      padding: 0;
      border-radius: 0;
      margin-bottom: 0;

      .twitter-typeahead,
      .tag {
        padding: 1rem;
        width: 100%;
      }

      .twitter-typeahead {
        width: 90% !important;

        .tt-hint {
          line-height: 23px;
          width: 100% !important;
          padding: 6px 12px;
        }

        .tt-input {
          border: none !important;
          width: 100% !important;
        }

        > .tt-menu {
          background: #fff;

          @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
          padding: 10px 0;

          .tt-suggestion {
            padding: 1px 12px;

            &:hover {
              //background: $gray-lighter;
              background: $red;

            }
          }
        }
      }

      .tag {
        background: transparent;
        color: inherit;
        font-size: inherit;
        font-weight: $font-weight-light;
        margin: 0;
        border-radius: 0;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 2;
        text-align: left;
        line-height: 22px;

        [data-role="remove"] {
          float: right;
          margin-left: 0;
          margin-right: 15px;
        }
      }

    }
  }

  .bootstrap-tagsinput {
    position: relative;
  }

  #map_container {
    max-width: 1920px;
    width: 100%;
    max-height: 2059px;
    //border: 1px solid $gray-lighter;
    border: 1px solid $black;

    height: 90vh;

    .marker-pin {
      stroke: #009DFF;
      stroke-opacity: 1;
      cursor: pointer;
    }

    .avz-search-box-clear-button {
      position: absolute;
      right: 0;
      top: 3px;
      z-index: 10;
    }

  }
}

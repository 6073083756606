.sticky-content { 
  button.navbar-toggler, .mmenu-toggler{
  border: 2px solid $secondary !important;
  padding: 0.3rem;
  padding-top: 0.4rem;
  padding-left: 0.47rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  }

  .navbar-light .navbar-toggler {
    color: $secondary;
    border: 1px solid $secondary;
  }
}

@media only print {
  .mm-wrapper {
    position: static !important;
  }
}

.mm-menu a.nav-link, .mm-menu a, .mm-menu a:link {
  @include media-breakpoint-down(md) {
    color: $primary !important;
  }
}

#bahnland_navigation_top_mobile.mm-menu_fullscreen {
  min-width: 380px !important;
}

#bahnland_navigation_top_mobile.mm-menu_popup {
  --mm-color-background: #{$white};
  --mm-color-text: #{$primary};
  --mm-color-border: transparent;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%)!important;
  height: 100%;
  max-height: none;

  .active.mm-listitem__text {
    text-decoration: underline;
    color: $secondary;
  }

  // mobile iconleiste aus dem template ausgeblendet. Ist über js eingeblendet

  .mm-menu_popup .active.mm-listitem__text {
      font-weight: $font-weight-bold !important;
  }

 .level_1 .mm-listitem, .level_2 .mm-listitem, .level_3 .mm-listitem {
    margin-top: 0rem;
    @include media-breakpoint-down(sm) {
      margin-top: 0.6rem;
    }
  }

  .mm-panels .level_1, .mm-panels .level_2, .mm-panels .level_3  {

    li, .mm-listitem_selected, .mm_listitem.active {
      background-color: $white;
      font-weight: $font-weight-bold !important;
      font-size: 2.3rem;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 1rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }
    }
    a, .mm-listitem_selected {
      text-align: left !important;
      font-weight: $font-weight-bold !important;
      border: 0px solid $white !important;
    }
  }

 #mm-1.mm-panel, .mm_listitem.active {
    //background-color: $blue-light;
  }

  .mm-panels .level_2,
  .mm-panels .level_3 {

    .mm-listitem {
      @include media-breakpoint-up(md) {
        margin-top: 4rem;
      }
      &:after {
        content: none;
      }
    }

    li, .mm-listitem_selected {
      //background-color: $blue-light;
    }

    a, .mm-listitem_selected {
      text-align: left !important;
      font-weight: $font-weight-normal !important;
    }

  }

  .mm-listitem_selected, .active.mm-listitem__text {
    background-color: transparent;
    font-weight: $font-weight-bold !important;

    &:after {
      border-bottom-width: 0;
    }
  }


}

.mm-menu {
  .mm-panels {
    .mm-navbar__title {
      color: $primary;
      padding: 0 !important;
      font-size: 1.4rem;
      font-weight: bold;

      @include media-breakpoint-up(md) {
        font-size: 2.3rem;
      }
    }

    .mm-listiten {
      position: relative;
    }
    // Arrow symbol
    a.mm-btn_next:not(:first-child), a.mm-btn_prev {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $white;

      &.mm-btn_prev {
        left: 0;
      }

      &.mm-btn_next{
        right: 0;
      }

      &:after, &:before {
        border-color: $primary;
      }
    }

    .first.mm-listitem {
      a.mm-btn {
        &.mm-btn_next{
          //padding-right: 0;
        }
      }
    }

    // End Arrow Symbol

    #mm-0 {
      // Header of main menu
      > .mm-navbar_sticky{
        display: none;
      }
    }

    #mm-1, #mm-2, #mm-3, #mm-4 {
      // Header of submenu
      .mm-navbar_sticky, .mm-btn {
        //background: $blue-light;
        font-weight: bold;
        line-height: 40px;
        font-size: 1.4rem;
      }
    }
  }

  .mm-navbar {

    @include media-breakpoint-up(xl) {
      padding: 0rem 0;
    }

    @include media-breakpoint-up(sm) {
      padding: 1rem 0 0 0;
    }

    .navbar {
      @include media-breakpoint-down(sm) {
        padding: 1rem 1.4rem;
      }
    }
  }

  .mm-navbars_bottom {
    .mm-navbar {
      padding: 0;
      display: flex;
      flex-direction: column;
      .navbar-secondary {
        padding: 1rem;

        .nav {
          justify-content: center;
        }
        a {
          color: $primary !important;
          font-size: inherit;
          @include media-breakpoint-up(md) {
            font-size: 1.8rem;
          }
          
        }
      }

      .navbar-mobile-bottom {
      position: unset;
      }
    }
  }

}
.mm-listview {
  display: flex !important;
  flex-direction: column !important;
  height: 90% !important;
  justify-content: flex-start !important;
}

.ce_text.block,
.ce_list {
  ul:not(.enclosure-list, .arrow-round) {
    list-style: none;
    padding: 0 0 0 1rem;
    li {
      margin-bottom: 1rem;
      @include beg-icon($beg-icon-arrow_carrot-right);

      &::before {
        clear: both;
        color: $cyan;
        display: block;
        float: left;
        font-weight: bold;
        margin-left: -23px;
        margin-top: 4px;
      }
    }
  }
}

.bg-gradient {
  .ce_text.block,
  .ce_list {
    ul:not(.enclosure-list) {
      li {
        color: $white;
        &::before {
          color: $yellow;
        }
      }
    }
  }
}

.ce_downloads {
  ul {
    list-style: none;
    .download-element {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1rem;
      position: relative;

      @include beg-icon($beg-icon-download);

      &::before {
        color: $primary;
        font-size: 2.4rem;
        padding: 0 0.5rem;
        text-align: center;
        transform: scale(1);
        transition: 300ms transform ease;
      }

      &:hover {
        &::before {
          transform: scale(1.1);
          transition: 300ms transform ease;
        }
        a {
          color: $secondary;
          transition: 200ms color ease;
        }
      }

      a {
        color: $primary;
        display: block;
        font-family: $font-family-poppins;
        font-style: italic;
        font-weight: 500;
        padding: 0.5rem;
        text-decoration: none !important;
        transition: 200ms color ease;
        background-color: transparent !important; //Downloads ohne weißen Hintergrund > unten noch einmal aufgeführt
        span {
          color: $black;
          display: block;
          font-family: $font-family-roboto;
          font-size: 90%;
          font-style: normal;
          font-weight: 300;
          text-transform: uppercase;
        }
      }
    }
  }

  li.download-element {
    a:after {
      content: unset;
    }
  }
}

.ce_download {
  p.download-element {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    position: relative;

    @include beg-icon($beg-icon-download);

    &::before {
      color: $primary;
      font-size: 2.4rem;
      padding: 0 0.5rem;
      text-align: center;
      transform: scale(1);
      transition: 300ms transform ease;
    }

    &:hover {
      &::before {
        transform: scale(1.1);
        transition: 300ms transform ease;
      }
      a {
        color: $secondary;
        transition: 200ms color ease;
      }
    }

    a {
      color: $primary;
      display: block;
      font-family: $font-family-poppins;
      font-style: italic;
      font-weight: 500;
      padding: 0.5rem;
      text-decoration: none !important;
      transition: 200ms color ease;
      background-color: transparent !important; //Downloads ohne weißen Hintergrund > unten noch einmal aufgeführt
      span {
        color: $black;
        display: block;
        font-family: $font-family-roboto;
        font-size: 90%;
        font-style: normal;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
}

.download-block {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 100%;
      @include media-breakpoint-up(md) {
        flex: 50%;
      }
    }
  }
}

.bg-blue-light {
  ul {
    li {
      a {
        background: $white;
      }
    }
  }
}

// For icon/symbol images
.icon-img {
  img {
    object-fit: contain;
  }
}

.image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;

  img {
    height: 100%;
    object-fit: cover;
  }
}
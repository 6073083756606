.animate__animated.animate__delay-250ms {animation-delay: 250ms;}
.animate__animated.animate__delay-250ms {animation-delay: 350ms;}
.animate__animated.animate__delay-500ms {animation-delay: 500ms;}
.animate__animated.animate__delay-750ms {animation-delay: 750ms;}
.animate__animated.animate__delay-760ms {animation-delay: 760ms;}
.animate__animated.animate__delay-770ms {animation-delay: 770ms;}
.animate__animated.animate__delay-780ms {animation-delay: 780ms;}
.animate__animated.animate__delay-790ms {animation-delay: 790ms;}
.animate__animated.animate__delay-800ms {animation-delay: 800ms;}
.animate__animated.animate__delay-810ms {animation-delay: 810ms;}
.animate__animated.animate__delay-820ms {animation-delay: 820ms;}
.animate__animated.animate__delay-830ms {animation-delay: 830ms;}
.animate__animated.animate__delay-840ms {animation-delay: 840ms;}
.animate__animated.animate__delay-850ms {animation-delay: 850ms;}
.animate__animated.animate__delay-860ms {animation-delay: 860ms;}
.animate__animated.animate__delay-870ms {animation-delay: 870ms;}
.animate__animated.animate__delay-880ms {animation-delay: 880ms;}
.animate__animated.animate__delay-890ms {animation-delay: 890ms;}
.animate__animated.animate__delay-900ms {animation-delay: 900ms;}
.animate__animated.animate__delay-910ms {animation-delay: 910ms;}
.animate__animated.animate__delay-920ms {animation-delay: 920ms;}
.animate__animated.animate__delay-930ms {animation-delay: 930ms;}
.animate__animated.animate__delay-1-25s {animation-delay: 1.25s;}
.animate__animated.animate__delay-1-5s  {animation-delay: 1.5s;}

.animate__duration-400ms  {animation-duration: 400ms;}
.animate__duration-500ms  {animation-duration: 500ms;}
.animate__duration-1s     {animation-duration: 1s;}
.animate__duration-1-5s   {animation-duration: 1.5s;}
.animate__duration-1-6s   {animation-duration: 1.6s;}

.hidden {visibility: hidden;}

.ce_huh_hero {
  .hero-container {
    @extend .container;

    .image_container {
      img,
      picture {
        width: 100%;
      }
    }
  }

  .hero-container {
    &.bb {
      @extend .container;
      padding-right: 0;
        padding-left: 0;

      @include media-breakpoint-down(sm) {
        --bs-gutter-x: 0 !important;
        background: $gradient;
        padding-right: 0;
        padding-left: 0;
      }

      .image_container {
        img,
        picture {
          width: 100%;
        }
      }
    }
  }

  .text-container {
    overflow-y: hidden;
    .diamond-hero-bg {
      height: 100%;
      background-repeat: no-repeat;
      display: flex;
      width: 50% !important;
      background-position: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-image: url(../../img/raute/raute-weiss.svg);

      .hero-sub-title {
        width: 65%;
        h1,
        .h1,
        p:first-child {
          display: flex;
          flex-direction: column;

          span {
            &:first-child {
              margin-bottom: 0;
            }
            &:nth-child(2) {
              margin-left: 2rem;
            }
          }
        }

        p:first-child {
          @extend h1;
        }

        p:not(.h1):not(:first-child) {
          @include font-size($lead-font-size);
        }
      }

      @include media-breakpoint-down(xxl) {
        width: 55% !important;
      }

      @include media-breakpoint-down(xl) {
        width: 65% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 80% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 95% !important;
      }

      @include media-breakpoint-down(sm) {
        width: 100% !important;
        padding: 1rem;
        .hero-sub-title {
          width: 100%;
        }
      }
    }

    .diamond-hero-white-bg {
      height: 100%;
      display: flex;
      width: 50% !important;
      justify-content: center;
      flex-direction: column;
      align-items: center;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url(../../img/raute/raute-weiss.svg);
      

      .hero-sub-title {
        width: 65%;
        h1,
        .h1,
        p:first-child {
          display: flex;
          flex-direction: column;

          span {
            &:first-child {
              margin-bottom: 0;
            }
            &:nth-child(2) {
              margin-left: 2rem;
            }
          }
        }

        p:first-child {
          @extend h1;
        }

        p:not(.h1):not(:first-child) {
          @include font-size($lead-font-size);
        }
      }

      @include media-breakpoint-down(xxl) {
        width: 55% !important;
      }

      @include media-breakpoint-down(xl) {
        width: 65% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 80% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 95% !important;
      }

      @include media-breakpoint-down(sm) {
        width: 100% !important;
        padding: 1rem;
        .hero-sub-title {
          width: 100%;
        }
      }
    }

    .diamond-blue-hero-bg {
      height: 100%;
      background-repeat: no-repeat;
      display: flex;
      width: 50% !important;
      background-position: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-image: url(../../img/raute/raute-blau.svg);

      .hero-sub-title {
        width: 65%;
        h1,
        .h1,
        p:first-child {
          display: flex;
          flex-direction: column;

          span {
            &:first-child {
              margin-bottom: 0;
            }
            &:nth-child(2) {
              margin-left: 0rem;
            }
          }
        }

        p:first-child {
          @extend h1;
        }

        p:not(.h1):not(:first-child) {
          @include font-size($lead-font-size);
        }
      }

      @include media-breakpoint-down(xxl) {
        width: 55% !important;
      }

      @include media-breakpoint-down(xl) {
        width: 65% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 80% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 95% !important;
      }

      @include media-breakpoint-down(sm) {
        width: 100% !important;
        padding: 1rem;
        background-color: $primary;
        .hero-sub-title {
          width: 100%;
        }
      }
    }

    .diamond-gradient-hero-bg {
      height: 100%;
      background-repeat: no-repeat;
      display: flex;
      width: 50% !important;
      background-position: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      background-image: url(../../img/raute/raute-verlauf.svg);


      // @include media-breakpoint-up(md) {
      //   background: none;
      // }

      .hero-sub-title {
        width: 65%;
        h1,
        .h1,
        p:first-child {
          display: flex;
          flex-direction: column;

          span {
            &:first-child {
              margin-bottom: 0;
            }
            &:nth-child(2) {
              margin-left: 2rem;
            }
          }
        }

        p:first-child {
          @extend h1;
        }

        p:not(.h1):not(:first-child) {
          @include font-size($lead-font-size);
        }
      }

      @include media-breakpoint-down(xxl) {
        width: 55% !important;
      }

      @include media-breakpoint-down(xl) {
        width: 65% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 80% !important;
      }

      @include media-breakpoint-down(lg) {
        width: 95% !important;
      }

      @include media-breakpoint-down(sm) {
        width: 100% !important;
        padding: 1rem;
        background-color: $gradient !important;
        background-image: none !important;
        .hero-sub-title {
          width: 100%;
        }
      }

      .btn {
        transform: skew(-10deg) !important;

        &.btn-primary {
          background: $yellow;
          color: $primary;
        }
      }

    }

    // The overlay title of the hero image
    @include media-breakpoint-down(sm) {
      position: unset !important;
    }
  }
}

.hero-slider {
  .ce_huh_hero {
    margin-bottom: 0;
  }

  .tns-outer {
    @include media-breakpoint-only(xs) {
      padding-bottom: 4rem !important;
    }
  }

  .tns-nav {
    position: absolute;
    bottom: 1rem;
    right: 50%;
    transform: translateX(50%);

    button {
      background-color: $white;
      border: 2px solid $primary;

      &.tns-nav-active {
        background-color: $yellow;
      }
    }
  }
}
